<template>
  <div>
    <div
      class="progress-number font-weight-bold border--text"
      :style="styleNumber"
    >
      {{progress}}
    </div>
    <div
      class="progress-linear"
      :style="styleLinear"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { round } from '@/utils/math';

export default {
  name: 'ProgressLinear',
  computed: {
    ...mapGetters('testMicro', [
      'length',
      'activeStep',
    ]),
    ...mapGetters('app', [
      'loading',
    ]),
    progress() {
      if (this.activeStep === 0) {
        return '5%';
      }
      if (this.loading) {
        return '100%';
      }
      const fraction = round((this.activeStep) / this.length);
      return `${(fraction * 100).toFixed(0)}%`;
    },
    styleNumber() {
      const distance = this.$vuetify.breakpoint.mdAndUp ? 69 : 61;
      return `bottom: ${distance}px`;
    },
    styleLinear() {
      const distance = this.$vuetify.breakpoint.mdAndUp ? 60 : 52;
      return `width: ${this.progress}; bottom: ${distance}px`;
    },
  },
};
</script>

<style lang="scss">
.progress-number {
  font-size: 14px;
  position: fixed;
  left: 10px;
  z-index: 4;
}

.progress-linear {
  border-bottom: 8px solid #F8D37D;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  position: fixed;
  z-index: 5;
}
</style>
